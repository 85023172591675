<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey" text @click="getErrorsReported">
                  <v-icon left>mdi-refresh</v-icon>
                  Mettre à jour
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="reportedErrors.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Utilisateur</th>
                        <th>Numéro de téléphone</th>
                        <th>Service</th>
                        <th>Problèmes</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item ,index) in reportedErrors"
                          :key="index"
                      >

                        <td class="font-weight-medium">
                          <a :href="'users/'+ item.user_id"
                             target="_blank"
                             class="text-no-wrap text-decoration-none font-weight-medium grey--text text--darken-3">
                            <v-avatar size="35">
                              <v-img
                                  v-if="item.user?.account?.photo"
                                  :src="fileUrl + item.user?.account?.photo"
                              />
                              <v-img
                                  v-else
                                  :src="require('@/assets/avatar.png')"
                              />
                            </v-avatar>
                            {{ item.user?.name }}
                          </a>
                        </td>

                        <td>
                          {{ item.user?.phone }}
                        </td>

                        <td class="font-weight-medium text-no-wrap">
                          <v-avatar class="rounded-lg" :color="item.service?.background_color" size="35">
                            <v-img contain :src="fileUrl + item.service?.image"></v-img>
                          </v-avatar>
                          {{ item.service?.name }}
                        </td>

                        <td>
                          <v-chip color="gifty" dark small
                                  @click="[dialog = true,listErrors = item.list_errors,other = item.other]">
                            <v-icon left dark small>mdi-alert</v-icon>
                            Voir
                            <v-icon right dark small>mdi-arrow-right</v-icon>
                          </v-chip>
                        </td>

                        <td>
                          {{ item.created_at }}
                        </td>

                        <td>
                          <v-btn icon @click="remove(item.id)">
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getErrorsReported"/>
                  </div>
                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getErrorsReported"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>


      <v-dialog v-model="dialog"
                max-width="500"
                persistent>
        <v-card>
          <v-card-title>
            Liste des problèmes
            <v-spacer/>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-10">

            <div v-if="listErrors && listErrors.length"
                 class="grey lighten-4 rounded-lg pa-4 mb-3">
              <h4 class="font-weight-bold mb-3 gifty--text">Problèmes sélectionnés</h4>
              <ul>
                <li v-for="(error, index) in listErrors" :key="index">{{ error.name }}</li>
              </ul>
            </div>


            <div v-if="other" class="grey lighten-4 rounded-lg pa-4">
              <h4 class="font-weight-bold mb-3 gifty--text">Autre</h4>
              <p>
                {{ other }}
              </p>
            </div>

          </v-card-text>
        </v-card>
      </v-dialog>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";

export default {
  components: {NoResults},
  data() {
    return {
      keyword: '',
      id: '',
      overlay: false,
      isLoading: false,
      reportedErrors: [],

      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      other: null,
      dialog: false,
      listErrors: [],
      fileUrl: process.env.VUE_APP_FILE_URL,
    };
  },
  methods: {
    getErrorsReported() {
      this.isLoading = true
      HTTP.get('/v1/errors-reported?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.reportedErrors = res.data.data.data

        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.post("/v1/errors-reported/delete", {
        id: this.id,
      }).then(() => {
        this.overlay = false;
        let i = this.reportedErrors.findIndex((el) => el.id === this.id);
        this.$delete(this.reportedErrors, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
  },
  created() {
    this.getErrorsReported();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },
    perPage() {
      this.pagination.current = 1
      this.getErrorsReported();
    },
    keyword() {
      this.getErrorsReported();
    },
  }
};
</script>

<style scoped></style>
